.exam-banner {
	background-image: url("../../assets/images/exam.jpg");

	span {
		display: inline-block;
		width: 100%;
	}

	.telc-wrapper {
		display: block;
		margin-top: 1rem;

		img {
			margin-left: 1rem;
			height: 35px;
		}
	}

	@media (min-width: 576px) {
		.telc-wrapper {
			img {
				height: 45px;
			}
		}
	}

	@media (min-width: 768px) {
		.telc-wrapper {
			img {
				height: 55px;
			}
		}
	}
}

.exam {
	margin-bottom: 2rem;

	&-heading {
		margin-top: 3rem;
		margin-bottom: .75rem;

		div {
			text-transform: uppercase;
			font-weight: 600;
		}
	}

	&-row {
		margin: 0 !important;

		&:nth-child(even) {
			background: var(--secondary-color);
		}
		padding: .5rem 0;

		&-price {
			text-align: right;
		}

		.line {
			@media (min-width: 576px) {

				&:not(:first-child) {
					display: none;
				}
			}
		}
	}
}
