.gtc {
	line-height: 1.8;

	h1 {
		margin-bottom: 1.5rem;
	}
	ol {
		padding-left: 1.8rem;
		margin: 0;

		li {
			margin-bottom: .75rem;
		}
	}
}