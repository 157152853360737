.statement-banner {
    background-image: url("../../assets/images/statement.jpg");
}

.statement {
	.sub-title {
		display: block;
		font-size: 1.25rem;
		margin-bottom: .5rem;
	}
	.description {
		margin-bottom: 2rem;

		p {
			margin-top: 0;
		}
	}


}