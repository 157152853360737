.home, .legal {
    .nav {
        border-bottom: 1px solid #ddd;

        @media (min-width: 768px) {
            border-bottom-width: 0;
        }

        .main-menu {
            ul {
                &.menu-list {
                    li {
                        a {
                            color: #333 !important;
                        }
                    }
                }
            }
        }
    }
}

.nav {
    z-index: 3;
    position: fixed;
    padding-top: 0.25rem;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: 768px) {
        position: absolute;
        background-color: transparent;
    }

    .container {
        display: flex;
        flex-direction: row;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        justify-content: space-between;

        .brand-wrapper {
            a {
                display: block;

                img {
                    height: 60px;
                }
            }
        }

        .main-menu {
            margin: auto 0 auto auto;
            color: #fff;

            ul {
                list-style: none;
                margin: 0;

                &.menu-list {
                    display: none;

                    @media (min-width: 768px) {
                        display: flex;
                    }

                    li {
                        a {
                            text-decoration: none;
                            color: #fff;
                            padding: .25rem .75rem;
                            font-size: .8rem;
                        }

                        svg {
                            vertical-align: sub;
                            margin-right: .25rem;
                        }
                    }
                }
            }

            .menu-toggler {
                position: relative;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 60px;
                height: 60px;
                cursor: pointer;
                transition: all .3s ease-in-out;

                .menu-burger {
                    width: 35px;
                    height: 3px;
                    background-color: var(--font-color);
                    border-radius: 5px;
                    transition: all .3s ease-in-out;

                    &:after, &:before {
                        content: " ";
                        position: absolute;
                        width: 35px;
                        height: 3px;
                        right: 0;
                        background-color: var(--font-color);
                        border-radius: 5px;
                        transition: all .3s ease-in-out;
                    }

                    &:before {
                        transform: translateY(-10px);
                    }

                    &:after {
                        transform: translateY(10px);
                    }
                }

                &.open {
                    .menu-burger {
                        //transform: translateX(-30px);
                        background: transparent;

                        &:before {
                            transform: rotate(45deg) translateX(0);
                        }

                        &:after {
                            transform: rotate(-45deg) translateX(0);
                        }
                    }
                }

                @media (min-width: 768px) {
                    display: none;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .container {
            .brand-wrapper {
                display: none;
            }
        }
    }
}

.back-drop {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.4);

    &.show {
        display: block;
    }
}
