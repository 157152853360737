.integration-banner {
	background-image: url("../../assets/images/integration.jpg");

	.banner-content {
		span {
			font-size: 1rem;
		}

		.bamf-wrapper {
			display: block;
			margin-top: 1rem;

			img {
				height: 75px;
			}
		}
	}
}

.integration {
	.detail-box {
		border-radius: .5rem;
		padding: .5rem;
		margin-bottom: 1.75rem;
		margin-top: 1.75rem;

		div[class^="col"] {

			&:not([class^="col-break"]) {
				margin-top: .5rem;
			}

			p {
				margin-bottom: .5rem;
				font-weight: 600;
				text-transform: uppercase;
				font-size: .8rem;
				letter-spacing: .6px;
			}
		}

		/*.text-primary {
			border-bottom: 2px solid var(--secondary-color);
		}*/

		.lesson-units {
			margin-top: .5rem;
			p {
				border-bottom: 2px solid var(--secondary-color);
			}
		}

		.ue-info {
			font-weight: bold;
			font-size: .8rem;
			margin-bottom: 1rem;
		}

		ul {
			list-style: none;
			padding-left: 0;
			margin-top: .5rem;

			li {
				margin-bottom: .5rem;
				font-size: .8rem;
			}
		}
	}

	.description {
		.info, .ue-info {
			font-weight: bold;
			font-size: .8rem;
			margin-bottom: .5rem;
		}

		p {
			margin-bottom: 2.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	p {
		margin-top: 0;

		span {
			display: block;
		}
	}
}
