.course-banner {
	background-image: url("../../assets/images/course.jpg");

	.banner-content {
		span {
			font-size: 1rem;
		}
	}
}

.course {
	.info-box {
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;
	}

	&-date {
		margin-top: 1rem;
	}

	.description {
		margin: 2rem 0;
	}

	.text-primary {
		border-bottom: 2px solid var(--secondary-color);
		margin-bottom: .25rem;
	}
}