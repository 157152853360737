.about-us-banner {
	background-image: url("../../assets/images/about-us.jpg");
}

.about-us {
	line-height: 1.8;
	
	.info-box {
		font-size: 1rem;
		font-style: italic;
		width: 90%;
		margin: 2rem auto 2rem auto;
	}
}