.contact-banner {
    background-image: url('../../assets/images/contact.jpg');
}

.contact {

    .description {
        margin-bottom: 2rem;
    }

    h1 {
        margin-bottom: 1rem;
    }

    h2 {
        padding-left: 15px;
    }

    b {
        display: inline-block;
        margin-bottom: .5rem;
    }

    .address {

        a {
            display: inline-block;
            border-radius: .25rem;
            margin-bottom: .5rem;
            text-align: center;
            color: inherit;
            transition: all .3s;

            &:hover {
                transition: all .3s;
                text-decoration: underline;
            }

            span {
                svg {
                    vertical-align: text-bottom;
                    margin-right: .35rem;
                }
            }

            &.google-link {
                padding-top: .25rem;
                margin-bottom: 1.5rem;;
                color: var(--brand-color);
                text-decoration: underline;

                svg {
                    vertical-align: middle;
                    font-size: 1rem;
                    margin-left: .25rem;
                }
            }
        }
    }

    .opening {
        margin-top: 1.5rem;

        @media (min-width: 768px) {
            margin-top: 0;
        }

        div {

            &:not(:last-child) {
                margin-bottom: .75rem;
            }
        }
    }

    .contact-item {
        padding: .5rem 1rem;
        text-decoration: none;
        color: #fff !important;
        background-color: var(--brand-color);
    }
}

/*
.contact-wrapper {
    background-color: var(--secondary-color) !important;

    .container {
        display: flex;
        flex-flow: row;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .contact-heading {
        padding-right: 2rem;
        border-right: 1px solid var(--font-color);
    }

    .contact-address {
        margin: 0 auto;
    }
}

.contact-form-wrapper {
    padding-top: 4rem;

    .contact-form {
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 0;
        margin: 0 -1.25rem;

        .fill-width {
            width: 100% !important;
        }
        .input-group {
            flex: 0 0 50%;
            margin-bottom: 1.5rem;

            &--fill {
                flex: 1;
            }
        }

        .contact-form__action {
            padding: 0 1.25rem;
            width: 100%;

            .button {
                margin-top: 1.5rem;
                width: 100%;
            }
        }
    }
}*/


