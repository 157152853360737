.prices-banner {
    background-image: url("../../assets/images/prices.jpg");
    background-position: center bottom;
}

.prices {
    margin-bottom: 2rem;

    h1 {
        margin-bottom: 1.5rem;
    }

    .course {
        font-weight: 600;
        margin-bottom: .5rem;

        &:not(:nth-child(0)) {
            margin-top: 3rem;
        }
    }

    .row {

        &.list-item {
            margin: 0;
            padding: 0.5rem 0;

            &:nth-child(even) {
                background: var(--secondary-color);
            }
        }

        &.list-item-course {
            margin: 0;
            padding: 0.5rem 0;

            &:nth-child(odd) {
                background: var(--secondary-color);
            }
        }

        .fee {
            font-weight: 500;

            span {
                letter-spacing: .8px;
                font-weight: 600;
            }

            @media (max-width: 575.98px) {
                margin-bottom: 1.2rem !important;
            }
        }

        .free {
            text-transform: uppercase;
            color: #0ea00e;
            letter-spacing: .8px;
        }
    }
}
