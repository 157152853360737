.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;

	.header-contact {
		.contact-item {
			position: relative;
			display: inline-block;
			//border: 1px solid var(--brand-color);
			border-radius: 5px;
			padding: .5rem .75rem;
			text-decoration: none;
			color: var(--brand-color);

			&+.contact-item {
				margin-left: 1.5rem;
			}

			span:first-child {
				position: absolute;
				background-color: #fff;
				padding: 0 .15rem;
				bottom: 6px;
				left: -12px;
			}
		}
	}
}
.home {
	.side-nav-bar {
		@media (min-width: 768px) {
			ul {
				li:first-child {
					display: none;
				}
			}
		}
	}
}
.side-nav-bar {
	position: relative;
	background-color: var(--secondary-color);

	ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		margin: 0;
		padding-left: 0;

		li {
			cursor: pointer;

			.brand-img-wrapper {
				position: relative;
				display: none;
			}

			a {
				display: block;
				text-decoration: none;
				padding: .75rem .3rem .5rem .2rem;
				color: initial;
				transition: all .2s;

				&.active {
					span {
						color: var(--brand-color);
						border-color: var(--brand-color) !important;
						transition: all .3s;
					}
				}

				&.home {
					display: inline-block;

					svg {
						vertical-align: text-bottom;
					}
				}
			}
		}
	}

	@media (min-width: 768px) {
		ul {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			li {
				flex: 1 0 auto;

				.brand-img-wrapper {
					display: block;

					a {
						padding-top: .25rem !important;
						padding-bottom: .25rem !important;
					}
				}

				a {
					padding: .5rem;
					text-align: center;

					span {
						border-bottom: 2px solid transparent;
					}

					&:hover {
						span {
							color: var(--brand-color);
							border-color: var(--brand-color);
							transition: all .3s;
						}
					}

					&.home {
						display: none;
					}
				}
			}
		}
	}
}
