.footer {
    position: sticky;
    background-color: var(--secondary-color);
    top: 100%;
    margin-top: 3rem;

    .path-footer {
        height: 75px;
        background-color: #fff;
        clip-path: polygon(100% 0%, 0% 100%, 0% 0%);
    }

    .container {
        padding: 2rem 1rem 1rem 1rem;

        .row {
            margin-bottom: 1rem;;

            .footer-row {
                margin-bottom: 1.5rem;
            }

            .footer-row-title {
                display: inline-block;
                text-transform: uppercase;
                font-size: .9rem;
                font-weight: 500;
                margin-bottom: .25rem;

                &.small-title {
                    text-transform: none;
                }
            }
        }
    }

    .copyright {
        font-size: .6rem;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            font-size: .8rem;

            a {
                margin-bottom: .25rem;
            }

            &.google-link {
                padding-top: .5rem;

                a {
                    color: var(--brand-color);
                    text-decoration: underline;

                    svg {
                        vertical-align: middle;
                        font-size: 1rem;
                        margin-left: .25rem;
                    }
                }
            }

            a {
                color: initial;
                text-decoration: none;
                display: block;
            }
        }
    }
}
