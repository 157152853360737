.text-primary {
  color: var(--brand-color) !important;
}

.text-light {
    color: var(--brand-light) !important;
}

.info-box {
    background: var(--brand-color);
    padding: .75rem 1rem;
    border-radius: .25rem;
    text-align: center;
    color: #fff;
    letter-spacing: .8px;
    line-height: 1.75;
}

.description {
    font-size: 1rem;
    letter-spacing: .8px;
    line-height: 1.75;
    //background-color: var(--secondary-color);
    //padding: 1.25rem 1rem;
    border-radius: .25rem;

    p {
        margin-top:0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

h1 {
  font-weight: 700;
}
