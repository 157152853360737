.banner {
    position: relative;
    overflow: hidden;
    isolation: isolate;
    height: 250px;

    .bg-gradient {
        background: linear-gradient(to right top,rgba(49,39,132,.9),rgba(227,6,19,.8));
        position: absolute;
		left: 0;
		right: 0;
		height: 100%;
    }

    .container {
        position: relative;
        height: 100%;

        .page-title {
            margin-left: auto;
        }

        .banner-content {
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;

            h1 {
                font-size: 2rem;
            }

            h1, span {
                text-shadow: 1px 1px 15px rgba(0,0,0,0.3);
            }

            @media (min-width: 576px) {
                width: auto;
            }
        }
    }

    @media (min-width: 768px) {
        height: 350px;

        .container {
            .banner-content {
                h1 {
                    font-size: 3rem;
                }
            }
        }
    }

    &-img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        //background-attachment: fixed;
    }
}
