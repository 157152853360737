.start-banner {
    display: none;
    background-image: url("../../assets/images/suez.png");
    background-size: 295px;
    height: 235px;

    @media (min-width: 768px) {
        display: block;
    }

    .bg-gradient {
        display: none;
    }

    .banner-content {
        span {
            display: block;
            font-size: 1.75rem;

            &:first-child {
                font-size: 2rem;
            }

            &:last-child {
                font-size: 1.5rem;
            }

            @media (min-width: 768px) {

                font-size: 2.5rem;

                &:first-child {
                    font-size: 2.75rem;
                }

                &:last-child {
                    font-size: 2.35rem;
                }
            }
        }
    }
}

.content-container {
    padding-top: 1.25rem;

    @media (min-width: 768px) {
        padding-top: 0;
    }
}

.start {
    &.default-margin {
        margin-top: 0;

        @media (min-width: 768px) {
            margin-top: 2.75rem;
        }
    }
    &-text {
        margin-top: 1rem;
        margin-bottom: 4rem;

        p {
            margin-top: 0;
        }
    }

    .info-wrapper {
        margin-top: 2rem;
        margin-left: auto;
    }

    .we-are-wrapper {
        margin-bottom: 2rem;

        .we-are {
            display: flex;
            flex-flow: column;
            height: 100%;
            background-color: var(--secondary-color);
            text-align: center;
            padding: 0.5rem;
            border-radius: 0.25rem;

            p {
                margin-top: 0;
                margin-bottom: .75rem;
            }

            span {
                margin: auto;
                &+span {
                    margin-top: 15px;
                }
            }

            .gast-img, .telc-img {
                height: 35px;
            }

            img {
                height: 45px;
            }

            @media (min-width: 576px) {
                img {
                    height: 55px;
                }
            }

            @media (min-width: 992px) {

                &.gast {
                    p {
                        flex: 0 !important;
                    }
                }

                .gast-img, .telc-img {
                    height: 55px;
                }
                img {
                    height: 75px;
                }
            }
        }
}

    .cards {
        margin-top: 2rem;
        justify-content: center;

        .card {
            border-radius: .25rem;
            cursor: pointer;
            margin-bottom: 2.5rem;

            &-img {
                height: 175px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: .25rem;
                position: relative;

                @media (min-width: 768px) {
                    height: 150px;
                }
            }

            &-title {
                position: absolute;
                background-image: linear-gradient(to right top, #312784, rgba(227, 6, 19, 0.8)) !important;
                padding: .75rem 0.5rem;
                border-bottom-left-radius: .25rem;
                border-bottom-right-radius: .25rem;
                text-transform: uppercase;
                text-align: center;
                transition: background .3s;
                bottom: 0;
                left: 0;
                right: 0;

                span {
                    color: #fff;
                }

                @media (min-width: 768px) {
                    padding: .25rem 0.5rem;
                }
            }

            &:hover {
                .card-title {
                    color: #fff !important;
                    background-image: linear-gradient(to right top, #312784, rgba(227, 6, 19, 0.8)) !important;
                    -webkit-background-clip: unset;
                    -webkit-text-fill-color: unset;
                }
            }
        }
    }
}

