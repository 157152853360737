@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--font-color);
  line-height: 1.5;
  background-color: var(--secondary-color);

  @media (min-width: 1200px) {
    font-size: 18px;
  }
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

@media (max-width: 575.98px) {
    h1 {
        font-size: 1.5em;
    }
}

#root {
  min-height: 100vh;
}

.layout {
  background-color: #fff;
}

hr {
    border-color: var(--secondary-color);
    border-style: solid;
}

.col-break  {
    width: 100%;

    &-sm {
        @media (min-width: 576px) {
            display: none;
        }
    }

    &-md {
        @media (min-width: 768px) {
            display: none;
        }
    }
}
