.default-margin {
	margin-top: 2.75rem;
}

.heading {
	position: relative;

	.path {
		clip-path: polygon(0% 100%, 100% 45%, 100% 100%);
		height: 135px;
		position: absolute;
		bottom: 0;
		width: 100%;
		display: block;
		background: white;
	}
}

.side-bar-container {
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	background-color: var(--secondary-color);
	transition: all 0.5s ease-in-out;

	@media (min-width: 768px) {
		z-index: 1;
	}

	&.open {
		left: 0;
	}

	@media (max-width: 767.9px) {
		height: 100%;
		width: 80%;
		left: -100%;
		overflow-x: hidden;

		&.close {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
  	}


	@media (min-width: 768px) {
		position: relative;
  	}
}
