.form  {
	//background-color: var(--secondary-color);
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-radius: .25rem;

	.row {

		div[class^="col"] {

			&:not(:last-child) {

				&:not([class^="col-break"]) {
					margin-bottom: 1rem;
				}
			}
		}
	}
}