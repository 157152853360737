.button {
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  box-shadow: none !important;
  border-style: solid !important;
  border-width: 1px;
  border-color: transparent;
  border-radius: .125rem;
  display: block;
  cursor: pointer;

  &-primary {
    color: #fff;
    background-color: var(--brand-color);
  }

  &-primary-outline {
    border-color: #fff;
    border-width: 2px;
    color: #fff;
    background-color: var(--brand-color);
  }

  &-fill-width {
    width: 100%;
  }
}
