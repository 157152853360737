.data-protection {
	h1 {
		margin-bottom: 1.5rem;
	}
	.section {
		line-height: 1.8;

		&-title {
			font-weight: bold;
			font-size: 1.25rem;
			margin-bottom: .25rem;

			&+p {
				margin-top: 0;
			}
		}
	}
	.contact-data {
		margin-bottom: 2.5rem;

		a {
			span {
				&:first-child {
					display: inline-block;
					color: initial;
					width: 65px;
				}
			}
		}
	}
}