.input-group {
    display: flex;
    flex-flow: column;

    label {
        padding-bottom: 0.25rem;
    }
}

input,
textarea {
    font-size: 1rem;
    line-height: 1;
    padding: .25rem 0.5rem;
    border-radius: .125rem !important;
    border: 1px solid var(--font-color);
    background-color: #fff !important;

    &:focus {
        outline: 0;
        border-color: var(--brand-color);
        box-shadow: none !important;
    }
}