.legal {
  .legal-banner {
    display: none;
    background-image: url("../../assets/images/suez.png");
    background-size: 295px;
    height: 235px;

    @media (min-width: 768px) {
      display: block;
    }

    .bg-gradient {
      display: none;
    }
  }
  .default-margin {
    margin-top: 0;

    @media (min-width: 768px) {
      margin-top: 2.75rem;
    }
  }
  .content-container {
    padding-top: 1.25rem;

    @media (min-width: 768px) {
      padding-top: 0;
    }
  }
}
