.toTop {
    position: fixed;
    right: 1.25rem;
    bottom: 6%;
    padding: .35rem .75rem .25rem .75rem;
    color: #fff;
    background: linear-gradient(to right top, rgba(49, 39, 132, .9), rgba(227, 6, 19, .8));
    border-radius: .25rem;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 1;

    svg {
        vertical-align: baseline;
    }
}
